<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF REQUEST NOT YET RELEASED REPORT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-layout col>
          <v-flex xs12 md4>
            <v-data-table
              dense
              :headers="headers2"
              :items="vouchered_items"
              height="600"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:item="{ item }">
                <tr @click="activerow(item)"
                    :class="{ 'info white--text': item.id === selectedDepositId }">
                  <td>
                    {{ item.id }}
                  </td>
                  <td>
                    {{ item.sector }}
                  </td>
                  <td>
                    {{ item.created_by.name }}
                  </td>
                  <td>
                    {{ item.district_by.district_name }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
          <v-flex xs12 md8 class="mx-2" v-if="data_items.length > 0">
            <v-card>
              <h3>
                Print |
                <v-icon class="mr-2" color="success" @click="print_data"
                        v-if="!printt && (!no_cheque_uploaded||auto_deb) && !upload">
                  {{ icons.mdiPrinter }}
                </v-icon>
                <v-progress-circular :size="25" :width="5" color="success" indeterminate
                                     v-if="printt"></v-progress-circular>
                Dis-Approved |
                <v-icon class="mr-2" color="error" @click="dis_approved_data"
                        v-if="!printt && (!no_cheque_uploaded||auto_deb) && !upload">
                  {{ icons.mdiThumbDown }}
                </v-icon>
                <v-progress-circular :size="25" :width="5" color="success" indeterminate
                                     v-if="printt"></v-progress-circular>
              </h3>
              <h2>Request Data | Total: P {{ total_amount }}</h2>
              <v-data-table
                dense
                :headers="headers"
                :items="data_items"
                height="600"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.request_id }}
                    </td>
                    <td>
                      {{ item.request_particulars.particulars }}
                    </td>
                    <td>
                      {{ item.description }}
                    </td>
                    <td>
                      {{ formatPrice(item.amount) }}
                    </td>
                    <td>
                      <v-icon
                        class="mr-2"
                        color="info"
                        @click="edit(item)"
                      >
                        {{icons.mdiPencilOutline}}
                      </v-icon>
                      <v-icon
                        class="mr-2"
                        color="error"
                        @click="delete_data(item)"
                      >
                        {{icons.mdiDeleteCircle}}
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
    <v-dialog v-model="edit_dialog" persistent max-width="80%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">EDIT AMOUNT</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-6">
          <v-text-field
            v-model="amount"
            label="Amount"
            dense
            outlined
            :rules="rules.default_max_45_character_and_no_empty_rule"
            type="number"
          ></v-text-field>

          <!-- alert -->
          <v-col cols="12" v-show="alert2">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message2}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="success"
              class="me-3 mt-4 w-full"
              @click="save_edit"
              v-if="!saving "
            >
              Save Details
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="success"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-card-text>
      </v-card>
      <v-btn color="error" @click="edit_dialog = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiAccountOutline, mdiAccountCashOutline, mdiInformationOutline, mdiPrinter
    , mdiCloudUpload
    , mdiMonitorEye
    , mdiDeleteCircle
    , mdiCheckDecagram
    , mdiThumbDown
    , mdiPencilOutline
  } from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      key: 0,
      show: false,
      saving: false,

      saving_data: false,
      printt: false,
      upload: false,
      edit_dialog: false,
      can_upload: false,
      auto_deb: false,

      alert: false,
      alert_message: '',
      amount: '0',
      saving_data_request: false,
      alert_request: false,
      alert2: false,
      alert_message2: '',
      alert_message_request: '',
      total_amount: 0,
      headers: [
        {text: 'ID', value: 'date_of_deposit', sortable: false},
        {text: 'Request ID', value: 'tin', sortable: false},
        {text: 'Particulars', value: 'last_name', sortable: false},
        {text: 'Description', value: 'last_name', sortable: false},
        {text: 'Amount', value: 'last_name', sortable: false},
        {text: 'Edit', value: 'last_name', sortable: false},
      ],
      headers2: [
        {text: 'Req.#', value: 'date_of_deposit', sortable: false},
        {text: 'Sector', value: 'last_name', sortable: false},
        {text: 'Created By', value: 'last_name', sortable: false},
        {text: 'District/Club', value: 'tin', sortable: false},
      ],
      data_items_originaldata_items_original: [],
      data_items: [],
      uploaded_data_items: [],
      data_items2: [],
      request_id: '',
      request_items: [],
      category_id: '',
      category_items: [],
      allocation_of_fund: '',
      payee: '',
      is_auto_debit: false,
      cheque_no: '',
      cheque_date: '',
      voucher_no: '',
      bank_id: '',
      bank_items: [],
      cheque_items: [],
      vouchered_items: [],
      selectedDepositId: -1,
      has_existing_voucher: false,
      existing_voucher: {},
      value_props: {},

      can_view_uploaded_cheque: false,
      is_deleting: false,

      no_cheque_uploaded: false,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiPrinter,
          mdiPencilOutline,
          mdiThumbDown,
          mdiCloudUpload,
          mdiMonitorEye,
          mdiCheckDecagram,
          mdiDeleteCircle,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.selected_month()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', [
        'user_id',
        'name',
        'branch_contact_no',
        'branch_address',
        'position',
      ]),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('request_data', ['update_request_data','delete_request_data']),
      ...mapActions('request', ['list_of_available_for_cash_voucher', 'dis_approved_request']),

      croppie(e) {
        this.show = true
        var files = e.target.files || e.dataTransfer.files
        if (!files.length) return

        var reader = new FileReader()
        reader.onload = e => {
          this.cropped = this.croppieImage = e.target.result
        }
        reader.readAsDataURL(files[0])
      },
      resett() {
        this.$refs.refInputEl.value = ''
        this.cropped = this.avatarImg
        this.croppieImage = ''
        this.alert = false
      },
      selected_month() {
        this.data_items = []
        this.data_items2 = []
        this.total_amount = '0'
        this.selectedDepositId = -1
        this.list_of_available_for_cash_voucher()
          .then(response => {
            this.vouchered_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      async activerow(value) {
        this.selectedDepositId = value.id
        this.data_items = value.request_data
        var tto = 0
        value.request_data.forEach(function (item) {
          tto += parseFloat(item.amount)
        });
        this.total_amount = this.formatPrice(tto)

        this.existing_voucher = value

      },
      edit(item) {
        if (confirm("Are You Sure to Edit Amount?") === true) {
          this.value_props = item
          this.edit_dialog = true
          this.amount = item.amount
        }
      },
      save_edit() {
        this.update_request_data({
          id: this.value_props.id,
          amount: this.amount,
        })
          .then(response => {
            var color = 'error'
            if (response.status === 200) {
              color = 'success'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            Object.assign(this.$data, initialState())
            this.selected_month()
          })
          .catch(error => {
            console.log(error)
          })
      },
      delete_data(value) {
        if (confirm("Are You Sure to Delete Data?") === true) {
          this.delete_request_data({
            id: value.id,
          })
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              Object.assign(this.$data, initialState())
              this.selected_month()
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      dis_approved_data() {
        this.printt = true
        if (confirm("Are You Sure to Dis-aprroved Request?") == true) {
          this.dis_approved_request({
            id: this.existing_voucher.id
          })
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              Object.assign(this.$data, initialState())
              this.selected_month()
            })
            .catch(error => {
              console.log(error)
            })
        }
        this.printt = false
      },
      async print_data() {
        this.printt = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []

        var info_array = []
        var cheque_array = {}
        var voc_no = 'CV#' + this.existing_voucher.voucher_no

        widths = [20, 40, 60, 200, 60,]
        payments_array.push([
          {text: 'ID', alignment: 'left', style: 'main_info'},
          {text: 'Req. ID', alignment: 'left', style: 'main_info'},
          {text: 'Particulars', alignment: 'left', style: 'main_info'},
          {text: 'Description', alignment: 'left', style: 'main_info'},
          {text: 'Amount', alignment: 'left', style: 'main_info'},
        ])
        var tott = this.total_amount
        this.data_items.forEach(function (item, index, payment) {
          payments_array.push([
            {
              text: item.id,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.request_id,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.request_particulars.particulars,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.description,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text:
                item.amount === 0
                  ? ''
                  : (item.amount / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
          ])
          if (Object.is(payment.length - 1, index)) {
            // execute last item logic
            payments_array.push([
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: 'TOTAL',
                alignment: 'right',
                style: 'tableExample2',
              },
              {
                text: tott,
                alignment: 'left',
                style: 'tableExample2',
              },
            ])
          }
        })

        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'REQ. ID: ',
                  {
                    text: this.existing_voucher.id,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'SECTOR: ',
                  {
                    text: this.existing_voucher.sector,
                    fontSize: 15,
                    bold: true,
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'CREATED BY: ',
                  {
                    text: this.existing_voucher.created_by.name,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'DISTRICT: ',
                  {
                    text: this.existing_voucher.district_by.district_name,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'PAYEE: ',
                  {
                    text: this.existing_voucher.payee,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE COMMUNITY CARES FOUNDATION',
                    {
                      text: 'GOODLIFE BLDG. NATIONAL HIGHWAY SAN JOSE DIGOS CITY DAVAO DEL SUR 8002\n',
                      style: 'subheader2'
                    },
                    {
                      text: 'S.E.C REG. No. WAITING CONTACT NO.: NA',
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'LIST OF REQUEST NOT YET RELEASED',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            '__________________________________________________________________________________________________',
            {
              text: 'REQUEST DETAILS',
              style: {
                fontSize: 8,
                alignment: 'left',
                bold: true,
              },
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return rowIndex === 0 ? '#CCCCCC' : null
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF TRUSTEES',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT C. ARROCO JR.',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'FOUNDER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (EXECUTIVE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },

            cheque_array,
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left',
                },
                {
                  columns: [
                    {
                      text: 'VOUCHER #:',
                      fontSize: 9,
                      bold: true,
                      alignment: 'right',
                    },
                    {
                      text: voc_no + ' (BLUE)',
                      fontSize: 10,
                      bold: true,
                      alignment: 'left',
                    },
                  ],
                },
              ],
            }
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.printt = false
      },
    },
  }
</script>
